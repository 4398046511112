<template>
  <div class="container text-center bg-primary text-white pt-0">
    <div class="container_main px-5 pt-2 d-flex flex-column justify-content-center align-items-center">
      <h1 class="flex-grow-1 d-flex align-items-center mb-0">
        <span>
          Your <strong>result</strong>
        </span>
      </h1>
      <badge :label="maxFlowDampened" class="pt-5 pb-5"></badge>
      <p class="flex-grow-1 d-flex align-items-center mb-0">
        <span>
          Your inspiratory flow is in the
          <strong v-if="result">optimal range</strong>
          <strong v-if="!result">non-optimal range</strong>
          for this {{selectedDevice.name}}.
        </span>
      </p>
    </div>
    <div class="container_footer p-3">
      <b-button variant="light" class="d-block w-100 p-2 mb-2" @click="$router.push('/share')">Share</b-button>
      <b-button variant="light" class="d-block w-100 p-2 mb-2" @click="$router.push('/main/countdown')">Try again</b-button>
      <b-button variant="light" class="d-block w-100 p-2" @click="$router.push('/landing')">Start over</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Badge from '~/components/Badge.vue'

export default {
components: {
    Badge,
  },

  computed: {
    ...mapGetters({
      selectedDevice: 'inhaler/selectedDevice',
      maxFlowDampened: 'inhaler/maxFlowDampened',
      result: 'inhaler/result',
    }),
  },
}

</script>