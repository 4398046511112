<template>
    <svg class="spinner" viewBox="0 0 224 224">
      <g class="fullrotation slow" style="position: absolute;">
        <circle 
        stroke-width="4" 
        r="109" 
        cx="112" 
        cy="112" 
        fill="none" 
        >
        </circle>
      </g>
      <g class="fullrotation" style="position: absolute;">
        <circle 
        stroke-width="4" 
        r="94" 
        cx="112" 
        cy="112" 
        fill="none" 
        style="transform: rotate(-90deg); transform-origin: 50% 50%;"
        >
        </circle>
      </g>
    </svg>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .spinner {
        width: 14rem;
        height:14rem;
        stroke-linecap: round;
        .fullrotation {
            stroke: #4dacaa;
            transform-origin: 50% 50%;
            animation-name: fullrotation;
            animation-duration: 6s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;

            &.slow {
                stroke: white;
                animation-duration: 10s;
            }
        }
        circle {
            animation-name: dash;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            stroke-dasharray: 405;
            // stroke-dashoffset: 1010;
        }
        .slow {
            circle {
                animation-duration: 3s;
            }
        }
    }
    @keyframes fullrotation {
        0%   {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0%   {
            stroke-dashoffset: 1810;
        }
        100%   {
            stroke-dashoffset: 1010;
        }
    }
</style>