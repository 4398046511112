<template>
  <div class="badge">
    <div class="badge_label">
      {{label}}
    </div>

    <spinner></spinner>

  </div>
</template>

<script>
import Spinner from '~/components/Spinner.vue'
export default {
  components: {
    Spinner,
  },
  props: [
    'label',
  ],
}
</script>

<style lang="scss">
  .badge {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    &_label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.2rem;
      background-color: #b53b26;
      width: 10rem;
      height: 10rem;
      border-radius: 9999px;
      font-weight: 600;
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }  
</style>